.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  gap: 15%;
  justify-content: center;
}

.about__me {
  height: 40.5rem;
  aspect-ratio: 1/2;
  border-radius: 2rem;
  background: linear-gradient(45deg, transparent, var(--color-primary), transparent);
  display: grid;
  /* place-items: center; */
}

.about__me_image {
  height: 40.5rem;
  aspect-ratio: 1/2;
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(7deg);
  transition: var(--transition);
  display: flex;
}

.about__me_image:hover {
  transform: rotate(0deg);
}

.about__content {
  justify-items: center;
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  justify-items: center;
}

.about__card {
  border: 1px solid transparent;
  width: 14rem;
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  background: var(--color-primary);
  transition: var(--transition);
}

.about__card-icon {
  margin-top: 1rem;
  color: var(--color-secondary);
  font-size: 2.2rem;
  margin-bottom: 1rem;
}

.about__card h3 {
  font-size: 1.2rem;
  color: var(--color-secondary-variant);
  transition: var(--transition);
}

.about__card h5 {
  font-size: 1rem;
  color: var(--color-secondary-variant);
  transition: var(--transition);
}

.about__card small {
  line-height: 1.1rem;
  font-size: 0.8rem;
  color: var(--color-light);
}

.about__card:hover {
  background: var(--color-secondary);
  border-color: var(--color-primary);
  cursor: default;
}

.about__card:hover .about__card-icon {
  color: var(--color-primary);
  transition: var(--transition);
}

.about__card:hover h3 {
  color: var(--color-white);
  transition: var(--transition);
}

.about__card:hover h5 {
  color: var(--color-primary);
  transition: var(--transition);
}

.about__card-items {
  display: flex;
  color: var(--color-white);
  justify-content: space-between;
  margin: 1rem 1rem;
  align-content: center;
}

.about__description {
  margin: 0;
  text-align: left;
  width: 100%;
}

.about__description p {
  margin: 1.6rem 0 1.6rem;
  color: var(--color-light);
}

.about__description span {
  color: var(--color-light);
}

.about__description ul {
  margin-bottom: 2.5rem;
}

.about__description ul li {
  margin-left: 1.6rem;
  list-style: circle;
  color: var(--color-white);
  font-weight: 300;
  font-size: 0.9rem;
  line-height: 2rem;
}

/* ==== Media querys for medium devices ===== */
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    /* width: 50%; */
    width: 324px;
    max-width: 324px;
    margin: 2rem 2rem;
    justify-self: center;
  }

  .about__content {
    justify-items: center;
    /* margin: 0 auto 4rem; */
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    margin: 3rem;
  }

  .about__description {
    margin: 0;
    text-align: left;
    width: 80%;
  }

  .about__description p {
    margin: 1rem 0 1.5rem;
  }
}

/* ==== Media querys for small devices ===== */
@media screen and (max-width: 600px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
    width: 35%;
  }

  .about__me {
    /* width: 35%; */
    margin: 1rem 1rem;
  }

  .about__content {
    place-items: center;
  }

  .about__cards {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 3rem;
  }

  .about__description {
    margin: 0;
    text-align: left;
    width: 80%;
  }

  .about__description p {
    margin: 1.5rem 0;
  }
}
