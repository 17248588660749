.header__container {
  padding-top: 1rem;
  text-align: center;
  height: 100%;
  position: relative;
}

.header__greeting {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--color-light);
  text-align: left;
  /* margin-top: -4rem; */
  margin-bottom: 7rem;
}

/* ===== Call To Action ===== */
.header__cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ===== Socials ===== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 1rem;
  margin-bottom: -4.5rem;
}

.header__socials::after {
  content: '';
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ===== Scroll down ===== */
.header__scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 200;
  font-size: 0.9rem;
  margin-bottom: -5rem;
}

/* ===== Me ===== */
.header__me {
  margin: 4rem;
  display: flex;
  border-radius: 3rem;
  overflow: hidden;
  transition: var(--transition);
  max-width: 1200px;
}

.header__me:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}

.header__card {
  position: relative;
  left: calc(50% - 10rem);
  width: 20rem;
  height: 12rem;
  margin-top: 0.2rem;
  background: var(--color-primary);
  border-radius: 1rem;
  box-shadow: 0 3rem 5rem rgba(0, 0, 0, 0.15);
  transition: var(--transition);
}

.header__card h5 {
  padding-top: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--color-secondary-variant);
  line-height: 1.2rem;
}

.header__card small {
  color: var(--color-light);
  line-height: 1.2rem;
}

.header__card:hover {
  left: calc(50% - 10rem);
  width: 20rem;
  height: 15rem;
  margin-top: 1rem;
}

.header__card-imgBx {
  position: absolute;
  top: -9rem;
  left: 50%;
  transform: translateX(-50%);
  /* width: 12rem; */
  /* height: 11rem; */
  width: 192px;
  /* background: var(--color-secondary-variant); */
  background: transparent;
  border-radius: 10rem 10rem 0 0;
  /* box-shadow: 0 15px 50px rgba(0, 0, 0, 0.15); */
  /* overflow: hidden; */
  transition: var(--transition);
}

.header__card:hover .header__card-imgBx {
  /* width: 16rem; */
  /* height: 16rem; */
  width: 250px;
  top: -10rem;
}

.header__card-imgBx img {
  width: 100%;
  max-width: 250px;
  border-radius: 50%;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--color-secondary-variant);
}

.header__card-content {
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transition: var(--transition);
}

.header__card:hover .header__card-content {
  padding-top: 7rem;
  transition: var(--transition);
}

.header__card-item {
  transition: var(--transition);
  color: var(--color-light);
  justify-content: space-between;
  margin: 0 0.8rem;
}

.header__card-item h3 {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--color-secondary-variant);
  line-height: 1.2rem;
}

/* ==== Media querys for medium devices ===== */
@media screen and (max-width: 1024px) {
  .header__me {
    border-radius: 2rem;
  }
}

/* ==== Media querys for small devices ===== */
@media screen and (max-width: 600px) {
  .header__me {
    border-radius: 1rem;
  }

  .header__socials .header__scroll_down {
    display: none;
  }
}
