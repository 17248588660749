.container.contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact__cards {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__card {
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  /* border-color: var(--color-primary); */
  /* background: var(--color-secondary-variant); */
  background: var(--color-primary);
  transition: var(--transition);
}

.contact__card .contact__card-icon {
  margin-top: 6px;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--color-secondary-variant);
  transition: var(--transition);
}

.contact__card h5 {
  font-size: 0.8rem;
  color: var(--color-secondary-variant);
  transition: var(--transition);
}

.contact__card a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
  color: var(--color-light);
  transition: var(--transition);
}

.contact__card:hover {
  /* background: transparent; */
  /* border-color: var(--color-primary-variant); */
  background: var(--color-secondary);
  border-color: var(--color-primary);
  cursor: default;
  transition: var(--transition);
}

.contact__card:hover .contact__card-icon {
  color: var(--color-primary);
  transition: var(--transition);
}

.contact__card:hover h3 {
  color: var(--color-white);
  transition: var(--transition);
}

.contact__card:hover h5 {
  color: var(--color-primary);
  transition: var(--transition);
}

.contact__card small {
  line-height: 1.1rem;
  font-size: 0.8rem;
  color: var(--color-light);
}

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: var(--color-secondary-variant);
  border: 1px solid var(--color-primary);
  resize: none;
  color: var(--color-white);
}

input:hover,
textarea:hover {
  background: var(--color-secondary);
  border: 1px solid var(--color-white);
  cursor: default;
}

/* ==== Media querys for medium devices ===== */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* ==== Media querys for small devices ===== */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
