.experience__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.experience__legend {
  padding-bottom: 2rem;
  text-align: center;
}

.experience__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
  /* place-items: center; */
  justify-items: center;
  justify-content: center;
}

.experience__card {
  border-radius: 2rem;
  padding: 2.2rem 2.2rem;
  border: 1px solid transparent;
  background: var(--color-primary);
  transition: var(--transition);
  /* width: 95%; */
  /* height: 95%; */
  /* margin: 0; */
  width: 22rem;
}

.experience__card h3 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  color: var(--color-secondary-variant);
  transition: var(--transition);
}

.experience__card .experience__details-icon {
  margin-top: 6px;
  color: var(--color-secondary-variant);
}

.experience__card h4 {
  font-size: 0.8rem;
  color: var(--color-white);
  transition: var(--transition);
}

.experience__card:hover {
  background: var(--color-secondary);
  border-color: var(--color-primary);
  cursor: default;
  transition: var(--transition);
}

.experience__card:hover .experience__details-icon {
  color: var(--color-primary);
  transition: var(--transition);
}

.experience__card:hover h3 {
  color: var(--color-white);
  transition: var(--transition);
}

.experience__card small {
  line-height: 1.1rem;
  font-size: 0.8rem;
  color: var(--color-light);
}

.experience__card-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1.1rem;
}

.experience__details {
  display: flex;
  gap: 0.6rem;
  margin-left: 1rem;
  padding: 0.5rem;
  text-align: left;
}

/* ==== Media querys for medium devices ===== */
@media screen and (max-width: 1024px) {
  .experience__cards {
    grid-template-columns: 1fr 1fr;
    gap: 0.6rem;
  }

  .experience__card {
    padding: 2.4rem;
    margin: 0 0.2rem;
  }

  .experience__card-content {
    padding: 1rem;
  }

  .experience__details {
    display: flex;
    gap: 0.6rem;
    margin-left: 0.5rem;
    padding: 0.2rem;
  }
}

/* ==== Media querys for small devices ===== */
@media screen and (max-width: 760px) {
  .experience__cards {
    grid-template-columns: 1fr;
    gap: 0.6rem;
  }

  .experience__card {
    padding: 2rem 1rem;
    margin: 0;
  }
}
