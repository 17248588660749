.dark-content {
  background: hsl(0, 0%, 0%);
  color: #fff;
}

.dark-mode-toggle {
  display: flex;
  align-items: right;
  gap: 4px;
  margin: 8px;
  background: var(--color-bg);
}

.dark-mode-toggle button {
  background: var(--color-bg);
  text-align: right;
  font-size: 24px;
}
